body {
  margin: 0;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  background-color: #f3f3f4;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 14px;
}
